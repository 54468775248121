import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "tabs"
    }}>{`Tabs`}</h1>
    <div className="box">
  <strong>Attention</strong>: the use of the components needs the{" "}
  <span className="pink">squid.js</span> included in the project
    </div>
    <div className="box">
  <strong>Attention + 1</strong>: Probably the interaction via Javascript should
  not work well in SPA applications (independent of the framework React,
  Angular, Vuejs, etc). For now we have no implementation but we will create in
  the future, for now just use the styles and create your own implementation
    </div>
    <h2 {...{
      "id": "default"
    }}>{`Default`}</h2>
    <div className="mb-3 tabs-container box" data-tabs>
  <ul className="tabs-header">
    <li className="active">Home</li>
    <li>Profile</li>
    <li>Messages</li>
    <li>Settings</li>
  </ul>
  <div className="tabs-wrapper-content">
    <div className="tab-content">Content 1</div>
    <div className="tab-content">Content 2</div>
    <div className="tab-content">Content 3</div>
    <div className="tab-content">Content 4</div>
  </div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="tabs-container" data-tabs>
  <ul class="tabs-header">
    <li>
      <!-- Tab 1 Header HTML -->
    </li>
  </ul>
  <div class="tabs-wrapper-content">
    <div class="tab-content">
      <!-- Tab 1 Content Html -->
    </div>
  </div>
</div>
`}</code></pre>
    <h2 {...{
      "id": "lined-style"
    }}>{`Lined Style`}</h2>
    <p>{`To change tab style just add `}<inlineCode parentName="p">{`.line-style`}</inlineCode>{` to `}<inlineCode parentName="p">{`.tabs-hader`}</inlineCode>{` container.
To change line color just change the attribute `}<inlineCode parentName="p">{`background-color`}</inlineCode>{` inside `}<inlineCode parentName="p">{`::before`}</inlineCode>{` element on `}<inlineCode parentName="p">{`<li>`}</inlineCode>{` with `}<inlineCode parentName="p">{`.active`}</inlineCode>{` class`}</p>
    <div className="mb-3 tabs-container box" data-tabs>
  <ul className="tabs-header line-style">
    <li className="active">Home</li>
    <li>Profile</li>
    <li>Messages</li>
    <li>Settings</li>
  </ul>
  <div className="tabs-wrapper-content">
    <div className="tab-content">Content 1</div>
    <div className="tab-content">Content 2</div>
    <div className="tab-content">Content 3</div>
    <div className="tab-content">Content 4</div>
  </div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="tabs-container" data-tabs>
  <ul class="tabs-header line-style">
    <!-- Tabs -->
  </ul>
  ...
</div>
`}</code></pre>
    <h2 {...{
      "id": "helpers"
    }}>{`Helpers`}</h2>
    <h2 {...{
      "id": "disabled"
    }}>{`Disabled`}</h2>
    <p>{`Just add `}<inlineCode parentName="p">{`.disabled`}</inlineCode>{` to disable a tab and prevent click`}</p>
    <h3 {...{
      "id": "active"
    }}>{`Active`}</h3>
    <p>{`Just add `}<inlineCode parentName="p">{`.active`}</inlineCode>{` to active default an tab on page load`}</p>
    <h3 {...{
      "id": "tabs-center"
    }}>{`Tabs Center`}</h3>
    <p>{`Add `}<inlineCode parentName="p">{`.tabs-center`}</inlineCode>{` to `}<inlineCode parentName="p">{`.tabs-header`}</inlineCode>{` container to centralize header tabs`}</p>
    <h3 {...{
      "id": "tabs-width"
    }}>{`Tabs Width`}</h3>
    <p>{`Add `}<inlineCode parentName="p">{`.tabs-width`}</inlineCode>{` to `}<inlineCode parentName="p">{`.tabs-header`}</inlineCode>{` container to Tabs grow to 100% container size`}</p>
    <h3 {...{
      "id": "minor-tabs"
    }}>{`Minor tabs`}</h3>
    <p>{`Add `}<inlineCode parentName="p">{`.sm`}</inlineCode>{` to each `}<inlineCode parentName="p">{`li`}</inlineCode>{` in tabs header to create minor tabs`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      